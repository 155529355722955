/* global valueFilterComponent */
class ModaValueFilterComponent extends valueFilterComponent {
    getWatch() {
        const superWatch = super.getWatch();
        superWatch['display'] = function (newValue, oldValue) {
            if(newValue) {
                this.emitEvent("filter-menu-open",this.filterField.ItemField);
            }
        };
        return superWatch;
    }
    
    mounted() {
        return async function () {
            let self = this;
            this.display = !this.isMobile;
            this.eventMenuClose = this.eventMenuClose.bind(this);
            this.subscribeEvent("filter-menu-open",this.eventMenuClose);
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                self.display = !self.isMobile;
            });
        };
    }

    eventMenuClose(menuName) {
        if(this.filterField.ItemField !== menuName && this.display)
            this.display = false;
    }

    getMethods() {
        const superMethods = super.getMethods();
        superMethods.eventMenuClose = this.eventMenuClose;
        return superMethods;
    }

    getTemplate() {
        return `<div class="filter" v-if="collections.length>0">
                    <div>
                        <p class="filter-title" @click="display = !display">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                            <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                        </p>
                    </div>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul class="unstyled row">
                          <template v-for="value of collections">
                            <li :key="'li-value-'+value.name" v-if="value.itemCount>0" class="w-100">
                                <div class="custom-control custom-checkbox">
                                    <input :key="'input'+value.name" class="custom-control-input" type="checkbox" v-model="value.active" :id="value.label" @click="launchFilter(value)" />
                                    <label :for="value.label" class="custom-control-label">{{value.label}} ({{value.itemCount}})</label>
                                </div>
                            </li>
                          </template>
                        </ul>
                    </section>
               </div>`;
    }
}

ModaValueFilterComponent.registerComponent();